import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/mb_home'
  },
  {
    path: '/mb_home',
    name: 'MbMain',
    component: () => import('@/views/main/index.vue'),
    children: [
      // {
      //   path: '/mb_home',
      //   name: 'MbHome',
      //   meta: {
      //     title: '分蛋科技'
      //   },
      //   component: () => import('@/views/home/index.vue')
      // },
      // {
      //   path: '/mb_selectedProducts',
      //   name: 'MbSelectedProducts',
      //   meta: {
      //     title: '分蛋科技'
      //     // title: '精选产品'
      //   },
      //   component: () => import('@/views/selectedProducts/index.vue')
      // },
      {
        path: '/mb_home',
        name: 'MbAcquisitionByMarketing',
        meta: {
          title: '分蛋科技'
          // title: '数字化营销'
        },
        component: () => import('@/views/acquisitionByMarketing/index.vue')
      },
      {
        path: '/mb_solutionPlan',
        name: 'MbSolutionPlan',
        meta: {
          title: '分蛋科技'
          // title: '行业解决方案'
        },
        component: () => import('@/views/solutionPlan/index.vue')
      },
      {
        path: '/mb_aboutUs',
        name: 'MbAboutUs',
        meta: {
          title: '分蛋科技'
          // title: '关于我们'
        },
        component: () => import('@/views/aboutUs/index.vue')
      },
      // {
      //   path: '/mb_cooperation',
      //   name: 'MbCooperation',
      //   meta: {
      //     title: '合作商户'
      //   },
      //   component: () => import('@/views/cooperation/index.vue')
      // },
      {
        path: '/mb_digitalize',
        name: 'MBdigitalize',
        meta: {
          title: '关于分蛋'
        },
        component: () => import('@/views/digitalize/index.vue')
      },
    ]
  }
]

const getPageTitle = (pageTitle: any) => {
  if (pageTitle) {
    return `${pageTitle}`
  }
  return ''
}

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  next()
})

export default router
